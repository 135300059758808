<template>
    <div>
        <div style="width:100%;display: flex;justify-content: flex-end;align-items: center;margin-bottom: 10px"
             v-if="position === sportsConst.ENABLE">

            <button class="badge badge-success" style="padding: 5px 15px;margin-right: 5px" @click="add2customercenter"
                    v-if="position"> 선택문의
            </button>
            <button class="badge badge-red" style="padding: 5px 15px;margin-right: 5px;background-color: #ff7f00"
                    @click="deleteBet" v-if="position"> 선택삭제
            </button>
            <button class="badge badge-red" style="padding: 5px 15px;margin-right: 5px" @click="deleteAllBetInfo"
                    v-if="position">
                전체삭제
            </button>

        </div>

        <div class="mybet" v-for="(betinfo,index) in betList" :key="index">
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_NOT">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList" :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>

<!--                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}
                            </span>-->
                          <span class="score text-orange"
                                v-if="betInfoGame.game.homeTeamScore != null && betInfoGame.game.awayTeamScore != null
                                && betInfoGame.game.homeTeamScore > -1 && betInfoGame.game.awayTeamScore > -1 ">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}
                            </span>
                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                      취소
                                    </span>
<!--                            <span class="score text-orange"-->
<!--                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">-->
<!--                                      적특-->
<!--                                    </span>-->
                            <span class="score text-white"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_WATING">
                                     대기중
                                    </span>
                            <span class="st"
                                  style="float: right">{{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}</span>
                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{betInfoGame.game.startTime|datef('DD일 HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_HOME}">
                                        <span class="home_name" v-html="betInfoGame.game.homeTeamName"></span>
                                        <span class="home_odds">
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square"  style="color: #0BC904"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-up text-red"></i>
                                            {{betInfoGame.homeTeamOdds}}
                                        </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds > 0"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_DRAW}">
                                        <span class="draw_bedang">{{betInfoGame.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                         class="draw ho">
                                        {{betInfoGame.handicapVal}}
                                    </div>
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                         class="draw ou">
                                        {{betInfoGame.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_AWAY}">
                                        <span class="away_name" v-html="betInfoGame.game.awayTeamName">
                                                                       </span>
                                        <span class="away_odds">
                                            {{betInfoGame.awayTeamOdds}}
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square" style="color: #0BC904"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-down text-blue"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                 <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                       적중
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            낙첨
                                </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                     적특
                                  </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 4">
                                     취소
                                  </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table">
                                    <tr>
                                        <th>승부타임</th>
                                        <th>구분</th>
                                        <th>선택항목</th>
                                        <th>선택배당</th>
                                        <th>배팅결과</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td class="inplay_selected">{{betInfoGame.ito3}}</td>
                                        <td><font style="font-weight: normal;color: #ffa500">{{betInfoGame.selectedOdds}}</font> <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text-white" v-if="betInfoGame.betGameResult === 0">
                                                대기
                                              </span>
                                            <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                                적중
                                         </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            낙첨
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                             적특
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 4">
                                             취소
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="betInfo">
                <div class="item">
                      <span class="chk">

                  <input v-if="position === sportsConst.ENABLE" type="checkbox"
                         @click="checkBetId(betinfo.id)" style="margin-right: 5px">

                     <a href="javascript:void(0)" class="badge badge-red"
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING
                       && betinfo.isinplay === sportsConst.INPLAY_NOT && position === sportsConst.ENABLE"
                        @click="cancelBet(betinfo.id)">취소하기</a>

                </span>
                    <span class="time" style="color: #fff9ff">
                  <i class="fa fa-clock-o"></i>&nbsp;
                  <i>{{betinfo.createTime|datef('MM/DD HH:mm:ss')}}</i>
                </span>
                    <span
                            v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">결과대기</span>
                    <span class="text-skyblue"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_WIN"><i class="fa fa-trophy"></i> 당첨</span>
                    <span class="text-red"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_LOSE">낙첨</span>
                    <span class="text-orange"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_SPECIALCASE">[적특]</span>
                    <span class="text-orange"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_CANCEL">[취소]
                            </span>

                </div>

                <div class="item">
                    <span class="odds text-skyblue">배당: {{betinfo.betOddsTotal}}</span>
                    <span class="betCash text-skyblue">배팅금: {{betinfo.betCash|comma}}</span>
                    <span class="wincash text-skyblue">
                예상당첨:
                {{(betinfo.totalCash)|comma}}&nbsp;<i v-if="betinfo.bonusCash > 0"
                                                      style="font-style: normal">(+{{betinfo.bonusCash|comma}})</i>
                </span>


                </div>


            </div>
        </div>
    </div>

</template>

<script>
    import {cancelBet, deleteAllBet, deleteBet} from "../../network/sportsBetRequest";
    import sportsConst from "../../common/sportsConst";
    import BoardWriter from "../BoardWriter";

    export default {
        name: "SportsBetListComp",
        components: {BoardWriter},
        data() {
            return {
                sportsConst,
                betIds: [],
            }
        },
        props: {
            betList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 배팅내역(삭제,취소,선택체크 를 숨김) 1: 배팅내역 리스트
            }
        },
        methods: {
            add2customercenter() {
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '배팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$router.push({
                    path: '/customer_center',
                    query: {sbid: this.betIds.join(","), t: new Date().getTime()}
                })
            },
            deleteAllBetInfo() {
                this.$swal({
                    title: '전체 배팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            cancelBet(id) {
                this.$swal({
                    title: '배팅내역을 취소하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        cancelBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            deleteBet() {
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '배팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                this.$swal({
                    title: '선택하신 배팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        let idsString = this.betIds.join(',');
                        console.log(idsString)
                        deleteBet({idsString: idsString}).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .league .score {
        float: right;
        margin: 0 3px;
    }

    .league .st {
        display: none;
    }

    .betInfo {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: var(--noticeBg);
        line-height: 36px;
        margin-bottom: 20px;
        border-bottom: 4px solid #ffffff;
    }

    .betInfo .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        color: #d9d9d9;
        box-sizing: border-box;
        padding-left: 3px;
    }
    .betInfo .item span{
        width: 33.3%;
    }



    .match_area table {
        font-size: 12px;
      text-align: center;
    }


    .match_area table th, td {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #535353;
    }

    .btn-del-all {
        width: 120px;
        height: 30px;
        background-color: #ff0000;
        margin: 5px 0;
        border-radius: 3px;
    }

    .match_area .inplay_team_name {
        margin-left: 20px;
        color: #f4c600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .league .st {
            display: inline-block;
            color: #c4c4c3;
            margin-right: 20px;
        }

        .match_area .inplay_team_name {
            margin-left: 5px;
        }

        .betInfo .odds {
            width: 26% !important;
        }

        .betInfo .betCash {
            width: 37% !important;
        }

        .betInfo .wincash {
            width: 37% !important;
        }

    }


</style>